import * as React from "react"

const styles = {
    section: (display) =>  ({
        width: '100%',
        height: '100vh',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        display: display ? 'flex' : 'none'
    }),
    videoContainer: {
        width: '50%',
        aspectRatio: "16/9"
    }
}
const VideoPresentation = (props) => {
    return(
        <section style={styles.section(props.display)} onClick={() => props.onClickOverlay()}>
            <div style={styles.videoContainer}>
                {props.display && 
                    <iframe width="100%" height="100%"  src={props.uri} title="Presentation" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                } 
            </div>
        </section>
    );

}

export default VideoPresentation;