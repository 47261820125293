import * as React from "react"
import MembleWhite from '../images/memble-color.svg';

const styles = {
    header: {
        width: '100%',
        color: 'white',
        height: '188px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        zIndex: '10'
    },
    navBar: {
        height: '70px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    social: {
        flex: 1,
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo: {
        flex: 1,
        width: '100%',
        height: '100%'
    },
    nav: {
        flex: 1,
        width: '100%',
        height: '100%',
        display: 'flex',
    },
    logoImg: {
        width: '100%',
        height: '100%'
    },
    socialIcon: {
        width: '40px',
        height: '40px',
        marginLeft: "10px",
        marginRight: "10px",
    }
}
const MainHeaderComponent = (props) => {
    return (
        <header style={styles.header}>
            <div style={styles.navBar}>
            <div style={styles.logo}>
                <img style={styles.logoImg} src={MembleWhite} />
            </div> 
            </div>   
        </header>
    )
}

export default MainHeaderComponent;