import * as React from "react"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const styles = {
  container: breakpoints =>  ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60px',
    width: '100%',
  }),
  anchor: breakpoints =>  ({
    marginLeft: "15px",
    marginRight: "15px",
    textDecoration: 'none',
    color: (breakpoints.xs || breakpoints.sm) ? 'black' : 'white',
    fontSize: "12px",
    fontWeight: "300",
    color: 'black'
  }),
  anchorDivider: {
    width: "2px",
    height: "2px",
    backgroundColor: 'white',
    borderRadius: '100%',
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '470px'
  }
}
const Footer = (props) => {
  const breakpoints = useBreakpoint();

    return(
        <section style={styles.container(breakpoints)}>
          <div style={styles.leftSide}>
            <a href="/conditions" style={styles.anchor(breakpoints)}>Conditions générales d'utilisations</a>
            <div style={styles.anchorDivider}></div>
            <a href="/privacy" style={styles.anchor(breakpoints)}>Politique de confidentialité des données</a>
          </div>
        </section>
    );

}

export default Footer;