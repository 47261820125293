import * as React from "react"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import VideoPresentation from "../components/video-presentation.component";
import Footer from "../components/footer.component";
import MainHeaderComponent from "../components/main-header.component";
import Blue from '../images/blue.svg';
import Pink from '../images/pink.svg';
import AppStore from '../images/app-store.svg';
import PlayStore from '../images/play-store.svg';
import { COLORS } from "../constants/colors";

const styles = {
  main: breakpoints => ({
    width: '100%',
    height: '100vh',
    backgroundColor: COLORS.BACKGROUND
  }),
  pinkImage: (breakpoints, widthScreen) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: (breakpoints.xs || breakpoints.sm) ? "100%" : "60%",
    zIndex: 0,
    minWidth: (breakpoints.xs || breakpoints.sm) ? "0px" : "700px",
    display: 'none'
  }),
  blueImage: breakpoints => ({
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: "500px",
    // display: (breakpoints.xs || breakpoints.sm) ? 'none' : 'flex'
    display: 'none'
  }),
  leftContainer: (breakpoints, widthScreen) => ({
    width: (breakpoints.xs || breakpoints.sm) ? '100%' : '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  }),
  presentation: breakpoints => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

  }),
  presentationHeading: {
    margin: 0,
    fontSize: "50px",
    lineHeight: "60px",
    fontWeight: 900,
    color: COLORS.PRIMARY,
    textAlign: 'center'
  },
  button: {
    backgroundColor: COLORS.PRIMARY,
    color: 'white',
    borderRadius: '50px',
    padding: '10px 25px 10px 25px',
    borderWidth: 0,
    fontSize: "24px",
    fontFamily: 'Nunito',
    marginTop: "32px",
    cursor: "pointer"
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%'
  },
  downloadBtnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    marginTop: "20px"
  }

};
const IndexPage = () => {
  const breakpoints = useBreakpoint();
  const [width, setWidth] = React.useState(0);

  React.useEffect(() => {
    setWidth(window.innerWidth);
  }, [])

  const [displayVideoPresentation, setDisplayVideoPresentation] = React.useState(false);

  return (
    <main style={styles.main(breakpoints)}>
      <MainHeaderComponent />
      <img style={styles.pinkImage(breakpoints, width)} src={(breakpoints.xs || breakpoints.sm) ? Blue : Pink} />
      <img style={styles.blueImage(breakpoints)} src={Blue} />

      <div style={styles.leftContainer(breakpoints, width)}>
        <div style={styles.presentation(breakpoints)}>
          <h2 style={styles.presentationHeading}>Create groups.</h2>
          <h2 style={styles.presentationHeading}>Share moments.</h2>

          <div style={styles.downloadBtnContainer}>
            <a href={"#"}>
              <img style={styles.downloadBtn} src={AppStore} />
            </a>
            <a href={"#"}>
              <img style={styles.downloadBtn} src={PlayStore} />
            </a>
          </div>


          {/* <button style={styles.button}  onClick={() => setDisplayVideoPresentation(true)}>Découvrir Memble</button> */}
        </div>
      </div>

      <div style={styles.footer}>
        <Footer />
      </div>

      <VideoPresentation onClickOverlay={() => setDisplayVideoPresentation(false)} display={displayVideoPresentation} uri="https://www.youtube.com/embed/BtyclGRwv9M" />
    </main>
  )
}




export default IndexPage
